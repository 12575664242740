/** @jsx jsx */
import { jsx, Flex } from "theme-ui"
import { BsTwitter, BsLinkedin, BsFacebook } from "react-icons/bs"

const ShareIcons = ({ title, url }) => {
  return (
    <Flex>
      <ShareLink
        url={`https://twitter.com/share?text=${encodeURIComponent(
          title
        )}&url=${url}`}
        socialMediaName="Twitter"
      >
        <BsTwitter />
      </ShareLink>
      <ShareLink
        url={`https://www.facebook.com/sharer/sharer.php?u=${url}`}
        socialMediaName="Facebook"
      >
        <BsFacebook />
      </ShareLink>
      <ShareLink
        url={`https://www.linkedin.com/sharing/share-offsite/?url=${url}`}
        socialMediaName="Linkedin"
      >
        <BsLinkedin />
      </ShareLink>
    </Flex>
  )
}

const ShareLink = ({ url, socialMediaName, children }) => (
  <a
    href={url}
    title={`Share on ${socialMediaName}`}
    target="_blank"
    rel="noopener noreferrer"
    sx={{
      width: "70px",
      height: "70px",
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      borderRadius: "100%",
      backgroundColor: "background.base",
      color: "tertiary.base",
      fontSize: "24px",
      mr: "16px",
      boxShadow: "1px 1px 2px 1px rgb(210 210 210)",
      "&:hover": {
        boxShadow: "0px 0px 2px 0px rgb(210 210 210)",
      },
    }}
  >
    {children}
  </a>
)

export default ShareIcons
