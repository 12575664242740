/** @jsx jsx */
import { jsx, Box, Flex, Heading, Grid, Text } from "theme-ui"
import { GatsbyImage, IGatsbyImageData } from "gatsby-plugin-image"
import parse from "html-react-parser"
import { BsTwitter, BsLinkedin, BsLink45Deg } from "react-icons/bs"

type AuthorBioProps = {
  author: {
    name: string
    bio?: string
    twitter?: string
    linkedin?: string
    website?: string
    image: {
      data: IGatsbyImageData
      alt: string
    }
  }
}

const AuthorBio: React.FC<AuthorBioProps> = ({ author }) => {
  return (
    <Grid
      sx={{
        gridTemplateColumns: ["1fr", null, "80px auto"],
        gridGap: 5,
        width: "100%",
      }}
    >
      <Box>
        <GatsbyImage
          image={author.image.data}
          alt={author.image.alt}
          sx={{ width: "80px", height: "80px" }}
          imgStyle={{
            borderRadius: "100%",
          }}
        />
      </Box>
      <Flex sx={{ flexDirection: "column" }}>
        <Heading
          as="h3"
          variant="sm"
          sx={{ fontWeight: "heading", color: "text.dark" }}
        >
          {author.name}
        </Heading>
        {author.bio && (
          <Text
            sx={{
              p: {
                mt: 0,
              },
              "p:last-child": {
                mb: 0,
              },
            }}
            as="div"
            mt={2}
          >
            {parse(author.bio)}
          </Text>
        )}
        <Flex mt={3}>
          {author.twitter && (
            <Icon icon={<BsTwitter />} title="Twitter" link={author.twitter} />
          )}
          {author.linkedin && (
            <Icon
              icon={<BsLinkedin />}
              title="LinkedIn"
              link={author.linkedin}
            />
          )}
          {author.website && (
            <Icon
              icon={<BsLink45Deg />}
              title="Website"
              link={author.website}
              custom={{
                width: "25px",
                height: "25px",
              }}
            />
          )}
        </Flex>
      </Flex>
    </Grid>
  )
}

const Icon: React.FC<{
  icon: React.ReactNode
  title: string
  link: string
  custom?: any
}> = ({ icon, title, link, custom }) => (
  <a
    href={link}
    target="_blank"
    rel="noopener noreferrer"
    aria-label={title}
    sx={{
      mr: 2,
      width: "22px",
      height: "22px",
      color: "text.base",
      "&:hover": {
        color: "primary.base",
      },
      svg: {
        width: "100%",
        height: "100%",
      },
      ...custom,
    }}
  >
    {icon}
  </a>
)

export default AuthorBio
