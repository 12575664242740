import React from "react"
import { Flex, Heading, Text } from "theme-ui"
import Button from "./Button"
import Cta from "./Cta"
import ShareIcons from "./ShareIcons"

type ContributeCtaProps = {
  socialShare: {
    title: string
    url: string
  }
}

const ContributeCta: React.FC<ContributeCtaProps> = ({ socialShare }) => (
  <Cta>
    <Flex
      sx={{
        flexDirection: ["column", null, "row"],
      }}
    >
      <Flex
        sx={{
          flexDirection: "column",
          alignItems: ["center", null, "flex-start"],
          width: ["100%", null, "50%"],
          pr: [0, null, 9],
          textAlign: ["center", null, "left"],
        }}
      >
        <Heading as="h2" variant="xl">
          Join the Data-centric AI Movement
        </Heading>
        <Text
          as="p"
          variant="md"
          sx={{
            fontWeight: "body",
            mt: 4,
            mb: 7,
            color: "text.light",
            lineHeight: "body",
            fontSize: ["18px", null, null, "22px"],
          }}
        >
          We want to share your Data-centric AI story. Fill out this Google form
          so we can feature your work!
        </Text>
        <Button variant="primary" as="internal_link" url="/contribute/">
          Contribute
        </Button>
      </Flex>

      <Flex
        sx={{
          borderLeft: ["none", null, "1px solid hsl(180deg 12% 78%)"],
          width: ["100%", null, "50%"],
          pl: [0, null, 9],
          flexDirection: "column",
          alignItems: ["center", null, "flex-start"],
          mt: [9, null, 0],
        }}
      >
        <Heading as="h3" variant="uppercase" mb={3}>
          Share
        </Heading>
        <ShareIcons title={socialShare.title} url={socialShare.url} />
      </Flex>
    </Flex>
  </Cta>
)

export default ContributeCta
