/** @jsx jsx */
import { jsx, Box, Flex, Grid, Container, Heading, Text } from "theme-ui"
import { Link } from "gatsby"
import { GatsbyImage, IGatsbyImageData } from "gatsby-plugin-image"
import parse from "html-react-parser"
import theme from "../../gatsby-plugin-theme-ui"

import AuthorsListCompact from "../AuthorsListCompact"

type PostHeaderProps = {
  fields: {
    title: string
    topic?: {
      title: string
      accentColor: string
      slug: string
    }
    excerpt?: string
    featuredImage?: {
      data: IGatsbyImageData
      alt: string
    }
    authors?: {
      image: {
        data: IGatsbyImageData
        alt: string
      }
      name: string
      shortBio: string
    }[]
    date: {
      formatted: string
      machineReadable: string
    }
  }
}

const Header: React.FC<PostHeaderProps> = ({ fields }) => {
  const { title, topic, excerpt, authors, featuredImage, date } = fields

  return (
    <Box as="header" sx={{ backgroundColor: "background.dark2x" }}>
      <Container py={10}>
        <Grid
          sx={{
            gridTemplateColumns: ["1fr", null, "1fr 1.3fr"],
            gridGap: 7,
            alignItems: "center",
          }}
        >
          <Flex sx={{ flexDirection: "column" }}>
            {topic && (
              <Heading
                as="div"
                variant="uppercase"
                sx={{ color: topic.accentColor }}
                mb={3}
              >
                <Link to={`/${topic.slug}/`}>{topic.title}</Link>
              </Heading>
            )}
            <Heading as="h1" variant="xl">
              {title}
            </Heading>
            {excerpt && (
              <Text
                as="div"
                variant="sm"
                mt={5}
                sx={{
                  p: { m: 0 },
                }}
              >
                {parse(excerpt)}
              </Text>
            )}
            <Box mt={5}>
              <AuthorsListCompact authors={authors} date={date} />
            </Box>
          </Flex>
          <Flex>
            <GatsbyImage
              image={featuredImage.data}
              alt={featuredImage.alt}
              imgStyle={{
                borderRadius: theme.radii[5],
                height: "100%",
              }}
              sx={{
                height: ["auto", null, null, "450px"],
              }}
            />
          </Flex>
        </Grid>
      </Container>
    </Box>
  )
}

export default Header
