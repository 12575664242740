/** @jsx jsx */
import React from "react"
import { jsx, Flex, Text } from "theme-ui"
import { Link } from "gatsby"

const Meta = ({ date, tags }) => {
  return (
    <Flex
      sx={{
        gridColumn: "wide-start/main-start",
        position: "relative",
        flexDirection: "column",
        alignItems: "flex-end",
        paddingRight: "40px",
        pt: "8px",
        display: ["none", null, null, null, "flex"],
      }}
    >
      {date && (
        <Flex
          sx={{
            flexDirection: "column",
            alignItems: "flex-end",
            textAlign: "right",
            mb: 5,
          }}
        >
          <Title>Published on</Title>
          <Text
            as="time"
            variant="xs"
            sx={{ fontWeight: "medium" }}
            dateTime={date.machineReadable}
          >
            {date.formatted}
          </Text>
        </Flex>
      )}
      {tags.length > 0 && (
        <Flex
          sx={{
            flexDirection: "column",
            alignItems: "flex-end",
            textAlign: "right",
          }}
        >
          <Title>Tagged with</Title>
          <Flex sx={{ flexWrap: "wrap", justifyContent: "flex-end" }}>
            {tags.map((tag, i) => (
              <React.Fragment key={i}>
                {i !== 0 && <span>,</span>}
                <Tag tag={tag} />
              </React.Fragment>
            ))}
          </Flex>
        </Flex>
      )}
    </Flex>
  )
}

const Title = ({ children }) => (
  <Text as="span" variant="uppercase" sx={{ fontSize: 0, mb: 2 }}>
    {children}
  </Text>
)

const Tag = ({ tag }) => (
  <Link
    to={`/tag/${tag.slug}/`}
    sx={{
      ml: 1,
    }}
  >
    <Text as="span" variant="xs" sx={{ fontWeight: "medium" }}>
      {tag.name}
    </Text>
  </Link>
)

export default Meta
