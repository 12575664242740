/** @jsx jsx */
import { jsx } from "theme-ui"
import { GatsbyImage } from "gatsby-plugin-image"

import AvatarIcon from "../components/icons/Avatar"

const AuthorsListCompact = ({ authors, date, collapsed = false }) => {
  return (
    <div
      sx={{
        display: "flex",
        alignItems: "center",
      }}
    >
      <div
        sx={{
          mr: authors?.length > 0 ? 2 : 0,
          display: "flex",
          alignItems: "center",
        }}
      >
        {authors?.map((author, index) => {
          if (collapsed && index > 1) {
            if (index === 2) {
              return (
                <div
                  sx={{
                    width: "53px",
                    height: "53px",
                    borderRadius: "100%",
                    border: "3px solid white",
                    marginLeft: index !== 0 ? "-13px" : 0,
                    position: "relative",
                    zIndex: 5 - index,
                    fontSize: "17px",
                    backgroundColor: "background.dark2x",
                    color: "text.light2x",
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    fontWeight: "medium",
                  }}
                  key={author.id + "_image_" + index}
                >
                  +{authors.length - 2}
                </div>
              )
            }
            return null
          }

          if (author?.image?.data) {
            return (
              <GatsbyImage
                image={author?.image?.data}
                alt={author?.image?.alt}
                style={{
                  width: "53px",
                  height: "53px",
                  marginLeft: index !== 0 ? "-13px" : 0,
                  position: "relative",
                  zIndex: 5 - index,
                }}
                imgStyle={{
                  borderRadius: "100%",
                  width: "53px",
                  height: "53px",
                  border: "3px solid white",
                }}
                objectFit="cover"
                objectPosition="50% 50%"
                key={author.id + "_image_" + index}
              />
            )
          } else {
            return (
              <div
                sx={{
                  width: "53px",
                  height: "53px",
                  borderRadius: "100%",
                  border: "3px solid white",
                  marginLeft: index !== 0 ? "-13px" : 0,
                  position: "relative",
                  zIndex: 5 - index,
                  padding: "10px",
                  backgroundColor: "background.dark2x",
                  color: "text.light2x",
                }}
                key={author.id + "_image_" + index}
              >
                <AvatarIcon />
              </div>
            )
          }
        })}
      </div>
      <div>
        <div sx={{ fontSize: 1, fontWeight: "medium" }}>
          {authors?.map((author, index) => (
            <span key={author.id + "_name_" + index}>
              {index !== 0 && `, `}
              {author.name}
            </span>
          ))}
        </div>
        <time
          dateTime={date.machineReadable}
          sx={{ fontSize: 1, color: "text.light2x" }}
        >
          Published {date.formatted}
        </time>
      </div>
    </div>
  )
}

export default AuthorsListCompact
