/** @jsx jsx */
import { jsx, Box } from "theme-ui"
import AuthorBio from "../AuthorBio"

const Author = ({ author }) => {
  return (
    <Box mt={9}>
      <AuthorBio author={author} />
    </Box>
  )
}

export default Author
