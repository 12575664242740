/** @jsx jsx */
import { Link } from "gatsby"
import { Box } from "theme-ui"
import { css, jsx } from "@emotion/react"
import theme from "../gatsby-plugin-theme-ui"
import { BsArrowRight } from "react-icons/bs"

type ButtonProps = {
  as?: "button" | "internal_link" | "external_link"
  variant?: "primary" | "secondary"
  url?: string
  arrow?: boolean
}

const baseStyles = css`
  font-size: 18px;
  height: 55px;
  padding: 0 24px;
  border-radius: 2px;
  border: none;
  background-color: ${theme.colors.primary.base};
  display: inline-flex;
  line-height: 1;
  align-items: center;
  color: ${theme.colors.background.base};
  font-weight: ${theme.fontWeights.medium};
  letter-spacing: ${theme.letterSpacings.links};
  cursor: pointer;
`

const Arrow = () => (
  <Box
    as="span"
    sx={{
      width: "18px",
      height: "18px",
      ml: 2,
      transition: "all 0.12s ease-in-out",
      "& svg": {
        width: "100%",
        height: "100%",
      },
    }}
  >
    <BsArrowRight strokeWidth="1" />
  </Box>
)

const Button: React.FC<ButtonProps> = ({
  as,
  variant,
  url,
  children,
  arrow,
  ...props
}) => {
  if (as === "button") {
    return (
      <button css={baseStyles} {...props}>
        {children}
      </button>
    )
  } else if (as === "internal_link") {
    return (
      <Link css={baseStyles} to={url} {...props}>
        {children}
        {arrow && <Arrow />}
      </Link>
    )
  } else if (as === "external_link") {
    return (
      <a css={baseStyles} href={url} {...props}>
        {children}
        {arrow && <Arrow />}
      </a>
    )
  }
}

export default Button
